import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import Box from '../../components/box'
import Image from '../../components/image'
import RowLayout from '../../components/rowLayout'
import TabPickerInverted from '../../components/tabPickerInverted'
import IntegrationCard from '../../components/integrationCard'
import CompanyIcons from '../../components/companyIcons'
import Hidden from '../../components/hidden'
import Accordion from '../../components/accordion'
import AccordionSingleSelect from '../../containers/accordionSingleSelect'
import checkUtmParams from '../../utils/checkUtmParams'

import useHotjarScript from '../../utils/hooks/useHotjarScript'
import { CheckMarkIcon } from '../../icons'
import { camelCase } from 'lodash'
import IntegrationSection from '../../components/integrationsSection'
import CloudAcademySection from '../../components/cloudAcademySection'
import HeroVideoBackground from '../../components/heroVideoBackground'
import Button from '../../components/button'
import TeamSection from '../../components/teamSection'
import FaqSection from '../../components/faqSection'
import { InnerSection, Section } from '..'
import BookCallModal from '../../components/bookCallModal'
import HeroVideo from '../../components/heroVideo'

const InternalCommsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        
        organizeVideosAndPosts: file(
          relativePath: { eq: "1.3/organize-videos-and-posts.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        leadershipVideoCards: file(
          relativePath: { eq: "1.3/leadership-video-cards.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        leadershipPostCards: file(
          relativePath: { eq: "1.3/leadership-post-cards.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        designVideoReader: file(
          relativePath: { eq: "1.3/design-video-reader.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        usersCaughtUp: file(
          relativePath: { eq: "1.3/users-caught-up.png" }
        ) {
          childImageSharp {
            fixed(height: 37, width: 193, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        salesStreams: file(
          relativePath: { eq: "1.3/sales-streams.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usersCaughtUp: file(
          relativePath: { eq: "1.3/users-caught-up.png" }
        ) {
          childImageSharp {
            fixed(height: 37, width: 193, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        salesStreams: file(
          relativePath: { eq: "1.3/sales-streams.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        internalCommsVideoReader: file(
          relativePath: { eq: "1.3/internal-comms-video-reader.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        internalCommsMobileReader: file(
          relativePath: { eq: "1.3/internal-comms-mobile-reader.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        internalCommsSlackNotifications: file(
          relativePath: { eq: "1.3/internal-comms-slack-notifications.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const integrations = []

  const toExtensionDownload = () => {
    navigate('browser-extension')
  }

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  const [isBookCallModalOpen, setIsBookCallModalOpen] = React.useState(false)

  return (
    <>
      <Nav />
      <BookCallModal
        open={isBookCallModalOpen}
        onClose={() => {
          setIsBookCallModalOpen(false)
        }}
      />
      <Box>
        <SEO
          title="Pulse - Message boards for modern teams"
          description="Pulse is a platform for team alignment. Engineered to help modern teams stay more connected and aligned by communicating more openly and asynchronously."
          ogImage="/assets/ogimages/pulse.png"
          twImage="/assets/ogimages/twitter/pulse.png"
        />
        <HeroVideoBackground className='-top-[116px] tabletLToLower:top-[368px]' />
        <div
          className='mt-[64px]'
        >
          <Section>
            <InnerSection className='flex justify-between pt-48 pb-[121.5px] tabletLToLower:pb-[40px] tabletLToLower:flex-col'>
              <div
                className='w-[496px] tabletLToLower:w-full pt-[30px]'
              >
                <div
                  className='text-base leading-22 text-gray-foreground-400 tabletLToLower:w-full tabletLToLower:text-center'
                >
                  Make it easy for your team to stay in the know
                </div>
                <div className='font-display mt-16 leading-[64px] text-[60px] tabletLToLower:text-4xl tabletLToLower:leading-[42px] tabletLToLower:text-center'>
                  Internal Comms
                </div>
                <div className='mt-16 text-gray-foreground font-display tabletLToLower:text-center tabletLToLower:text-base tabletLToLower:leading-[20px] max-w-[420px] text-xl leading-24'>
                  Connect people to information, make it easy to find important company comms, measure alignment and engagement.
                </div>
                <div className='mt-24 flex tabletLToLower:justify-center'>
                  <Button to='/signup/' className='px-18'>
                    <span className='tabletLToLower:hidden block'>
                      Try Pulse for free
                    </span>
                    <span className='tabletLToLower:block hidden'>
                      Create an account
                    </span>
                  </Button>
                  <Button className='ml-12 px-18 tabletLToLower:!hidden' secondary onClick={() => setIsBookCallModalOpen(true)}>
                    Get Demo
                  </Button>
                </div>
              </div>
              <HeroVideo />
            </InnerSection>
          </Section>
          <Section
            className='flex justify-center text-center pt-96 tabletLToLower:pt-64 pb-12'
          >
            <InnerSection style={{ width: '650px' }} className='tabletLToLower:!w-full'>
              <div className='text-5xl leading-52 font-display tabletLToLower:text-4xl tabletLToLower:leading-[42px] text-center'>
                The HQ for vital company news and staff updates.
              </div>
              <div className='text-gray-foreground leading-24 text-xl mt-16 flex justify-center tabletLToLower:text-base tabletLToLower:leading-[20px]'>
                <div style={{ width: '496px' }} className='tabletLToLower:!w-full'>
                  With Pulse, your team has a secure home where they can find the latest and most important comms. From company all-hands meetings to the latest policies and department updates.
                </div>
              </div>
            </InnerSection>
          </Section>
          <Section
            className='grid place-content-center pt-48 tabletLToLower:pt-32'
          >
            <div
              className='grid-cols-2 gap-x-16 gap-y-24 grid tabletLToLower:grid-cols-1'
            >
              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    01
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Your all-hands in one place
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  All transcribe and searchable
                </div>
                <div className='mt-[5px] grid place-content-center'>
                  <Img
                    fadeIn={false}
                    fixed={data.leadershipVideoCards.childImageSharp.fixed}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    02
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Send Slack notifications
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  On new updates and videos.
                </div>
                <div className='mt-[14px] grid place-content-center'>
                  <Img
                    fadeIn={false}
                    fixed={data.internalCommsSlackNotifications.childImageSharp.fixed}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    03
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Measure engagement
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Who read and who missed.
                </div>
                <div className='mt-[26px]'>
                  <Img
                    fadeIn={false}
                    fixed={data.usersCaughtUp.childImageSharp.fixed}
                  />
                </div>
                <div className='mt-[10px]'>
                  <Img
                    fadeIn={false}
                    fluid={data.internalCommsVideoReader.childImageSharp.fluid}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    04
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Catch up on the go.
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Important comms available 24/7.
                </div>
                <div className='mt-[25px] flex justify-center'>
                  <Img
                    fluid={data.internalCommsMobileReader.childImageSharp.fluid}
                    fadeIn={false}
                    style={{
                      width: '188px'
                    }}
                  />
                </div>
              </div>

            </div>
          </Section>
          {/* <div className='mt-[144px] tabletLToLower:mt-64' />
          <Section>
            <InnerSection className='pt-48 px-104 bg-gray-foreground-100 tabletLToLower:hidden' style={{ borderRadius: '32px' }}>
              <div className='flex justify-center'>
                <div className='text-5xl leading-52 w-640 text-center font-display'>
                  More signal, less noise. Build a focused DAO.
                </div>
              </div>
              <div className='flex justify-center'>
                <div className='mt-16 text-gray-foreground text-xl leading-24 text-center w-496'>
                  You can’t build productive DAOs on Discord only. It’s too noisy, it’s transcribed so the content is readable and accessible by default. Every video come with rich analytics so you what’s being watched.
                </div>
              </div>
              <div className='mt-48'>
                <Img
                  fluid={data.organizeVideosAndPosts.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
            </InnerSection>
          </Section> */}
          <div className='mt-[192px] tabletLToLower:mt-64' />
          <IntegrationSection team='internal-comms' />
          <FaqSection />
        </div>
        <Footer />
      </Box>
    </>
  )

}

export default InternalCommsPage
